.overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    z-index: 12345667;
    background-color: #000000ad !important;
}

/* The content */
.overlay-content {
    position: relative;
    top: 46%;
    width: 80%;
    text-align: center;
    margin-top: 30px;
    margin: auto;
}

/* Close button */
.overlay .closebtn {
    position: absolute;
    top: -60px;
    right: 0;
    font-size: 24px;
    cursor: pointer;
    color: white;
}

.overlay .closebtn:hover {
    color: #ccc;
}

/* Style the search field */
.overlay input[type='text'] {
    padding: 15px;
    font-size: 17px;
    border: none;
    float: left;
    width: 80%;
    background: white;
    height: 58px;
}

.overlay input[type='text']:hover {
    background: #f1f1f1;
}

/* Style the submit button */
.overlay button {
    float: left;
    width: 20%;
    padding: 15px;
    background: var(--tc-primary-500);
    font-size: 17px;
    border: none;
    cursor: pointer;
}

.overlay button:hover {
    background: #bbb;
}
