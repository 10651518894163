.box-banner-4 {
    /* background-image: linear-gradient(to bottom, #000d14b2, #002B43); */
    background: url(../imgs/pages/home4/banner2.png) no-repeat top center;
    padding-top: 300px;
    padding-bottom: 200px;
    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: url(../imgs/pages/home4/trans.png) no-repeat center;
        background-size: cover;
    }
    .container {
        position: relative;
        z-index: 2;
    }
    .box-banner-video {
        position: relative;
        .btn-play-4 {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;
            z-index: 2;
            height: 118px;
            width: 118px;
        }
    }
}
.box-chackout {
    padding: 120px 0px;
}
.box-our-process {
    padding: 120px 0px;
    background-color: var(--tc-primary-500);
}
.box-list-process-2 {
    display: flex;
    align-items: center;
    margin: 0px -15px;
    flex-wrap: wrap;
    .item-process {
        padding: 20px 15px;
        position: relative;
        width: 25%;
        &:hover {
            .icon-process {
                img {
                    transform: rotateY(180deg);
                    transition: 0.3s linear;
                }
            }
        }
        .icon-process {
            margin-bottom: 30px;
            img {
                transition: 0.3s linear;
            }
        }
        .info-process {
            h6,
            p {
                color: var(--tc-theme-main);
                margin-bottom: 20px;
            }
        }
        &::before {
            content: '';
            height: 100%;
            width: 1px;
            background-color: #ffffff63;
            position: absolute;
            top: 0px;
            right: 15px;
            z-index: 1;
        }
        &:last-child {
            &::before {
                display: none;
            }
        }
    }
}
.link-white {
    color: var(--tc-theme-main);
    svg {
        stroke: var(--tc-theme-main);
    }
}
.text-under {
    text-decoration: underline;
}
.box-client-logo {
    padding: 100px 0px;
}
.box-about-us-4 {
    background-color: #0055ff52;
    padding: 120px 0px 80px 0px;
    background-image: url(../imgs/pages/home4/bg-about.png);
    background-repeat: no-repeat;
    background-position: center right;
}
.box-progress-primary {
    .progress {
        background-color: #e3e3e380;
    }
    .progress-bar {
        background-color: var(--tc-primary-500);
    }
}
.box-testimonials-4 {
    padding: 120px 0px;
}
.box-dedicated-2 {
    padding: 120px 0px;
    background-image: url(../imgs/pages/home4/bg-dedicated.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
}
.box-plan-2 {
    background-color: var(--tc-theme-main);
    padding: 120px 0px;
    background-image: url(../imgs/pages/home4/bg-pricing.png);
    background-position: center top;
    background-repeat: no-repeat;
}
.swiper-pagination-style-2 {
    bottom: 0px !important;
    margin-bottom: -50px;
    .swiper-pagination-bullet {
        background-color: var(--tc-primary-500_2);
        margin: 0px 9px !important;
        position: relative;
        &:hover,
        &.swiper-pagination-bullet-active {
            background-color: var(--tc-primary-500);
            &::before {
                content: '';
                height: 20px;
                width: 20px;
                border: 1px solid var(--tc-primary-500);
                border-radius: 50%;
                position: absolute;
                top: -5px;
                left: -5px;
            }
        }
    }
}
.box-blog-4 {
    padding: 120px 140px;
}
.box-blog-4-inner {
    border-radius: 20px;
    background-color: var(--tc-secondery-600);
    padding: 80px 100px;
}
.footer-style-3 {
    background-color: var(--tc-theme-main);
    .grey-100 {
        color: #012236d5;
    }
    .hover-effect:hover {
        color: var(--tc-secondery-600) !important;
    }
    .text-footer {
        color: var(--tc-theme-heading-2);
        font-weight: bold;
        font-family: var(--tc-body-font-family);
    }
    .list-socials {
        .share {
            border: 0px;
            background-color: var(--tc-theme-bg-4);
            background-image: none;
            line-height: 44px;
            svg {
                fill: var(--tc-secondery-600) !important;
            }
            &:hover {
                background-color: var(--tc-secondery-600);
                svg {
                    fill: var(--tc-theme-main) !important;
                }
            }
        }
    }
    .form-newsletter .form-control {
        background-color: var(--tc-graay-50);
    }
    .footer-bottom {
        border-top-color: #00040788 !important;
    }
    .text-inline {
        svg {
            fill: var(--tc-primary-500);
        }
    }
}
.opacity-8 {
    opacity: 0.8 !important;
}
.box-contacts-2 {
    background-image: url(../imgs/pages/home4/bg-top-footer.png);
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    padding: 100px 0px;
    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-image: url(../imgs/pages/home4/bg-trans.png);
    }
    .container {
        position: relative;
        z-index: 2;
    }
}
.form-group {
    margin-bottom: 20px;
}
.form-control {
    padding: 10px 20px;
    border-radius: 5px;
    background-color: var(--tc-theme-main);
    height: 50px;
    border: 0px;
}
.box-services-4 {
    background-color: var(--tc-theme-main);
    padding: 120px 0px;
    background-image: url(../imgs/pages/home4/bg-service.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}
.box-ul {
    max-width: 356px;
    background-color: var(--tc-theme-main);
    padding-bottom: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    z-index: 2;
    box-shadow: var(--tc-shadow-1);
    ul {
        background-color: var(--tc-primary-50);
        list-style: none;
        padding: 0px;
        margin: 0px;
        li {
            width: 100%;
            a {
                padding: 8px 30px;
                font-size: 20px;
                line-height: 40px;
                font-weight: bold;
                font-family: var(--tc-body-font-family);
                color: var(--tc-dark-950);
                width: 100%;
                display: block;

                &:hover,
                &.active {
                    background-color: var(--tc-primary-500);
                    color: var(--tc-theme-main);
                }
            }
        }
    }
}
.phone-service {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    font-family: var(--tc-body-font-family);
    span {
        display: inline-block;
        height: 60px;
        width: 60px;
        line-height: 60px;
        text-align: center;
        background-color: var(--tc-theme-main);
        border-radius: 50%;
        box-shadow: var(--tc-shadow-1);
        margin-right: 20px;
    }
}
.box-content-tab {
    text-align: right;
    .tab-content {
        max-width: 86%;
        width: 100%;
        display: inline-block;
        text-align: left;
    }
}
.tab-div {
    padding: 361px 74px 60px 202px;
    .link-white {
        font-weight: bold;
        font-family: var(--tc-body-font-family);
    }
}
.tab-affordable {
    background-image: url(../imgs/pages/home4/banner-service6.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}
.tab-knowledge {
    background-image: url(../imgs/pages/home4/banner-service2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}
.tab-savetimes {
    background-image: url(../imgs/pages/home4/banner-service3.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}
.tab-fastquality {
    background-image: url(../imgs/pages/home4/banner-service4.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}
.tab-experienced {
    background-image: url(../imgs/pages/home4/banner-service5.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}
.box-image-about-home-4 {
    position: relative;
    display: inline-block;
    &::before {
        content: '';
        height: 200px;
        width: 200px;
        background-image: url(../imgs/pages/home4/star.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        bottom: 0px;
        right: -30px;
    }
    .btn-play {
        position: absolute;
        height: 118px;
        width: 118px;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        margin: auto;
        z-index: 3;
    }
}
