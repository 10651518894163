.content-detail-team {
    p {
        font-size: 16px;
        line-height: 28px;
        color: var(--tc-neutral-1500);
        font-weight: 400;
        font-family: var(--tc-rubik-font-family);
    }
}
.box-progress-team {
    .progress-bar {
        position: relative;
        overflow: visible;
        background-color: transparent;
        background-image: linear-gradient(to right, #1AB69D, #31B978);
        .percent {
            position: absolute;
            top: -40px;
            right: 0px;
        }
    }
    .progress {
        overflow: visible;
        margin-bottom: 30px;
    }
}
.percent {
    color: var(--tc-neutral-2700);
}
.content-detail-team {
    p {
        margin-bottom: 40px;
    }
}
.form-message {
    .form-control {
        background-color: var(--tc-theme-bg-16);
        border-radius: 10px;
        font-size: 16px;
        color: var(--tc-neutral-2800);
    }
    .btn-linear-03 {
        letter-spacing: 2px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
.team-socials {
    margin-bottom: 40px;
    a {
        display: inline-block;
        height: 45px;
        width: 45px;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
        background-color: var(--tc-dark-950);
        margin-right: 11px;
        svg {
            fill: var(--tc-theme-main);
        }
        &:last-child {
            margin-right: 0px;
        }
        &:hover {
            background-image: linear-gradient(to right, #1AB69D, #31B978);
        }
    }
}