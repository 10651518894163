.box-info-project {
    background-image: linear-gradient(to right, #1AB69D, #31B978);
    padding: 30px 30px 30px 60px;
    border-top-left-radius: 30px;
    position: relative;
    left: -60px;
    width: calc(100% + 60px);
    p {
        margin-bottom: 0px;
        color: var(--tc-theme-main);
    }
}
.blog-detail {
    h2 {
        font-size: 48px;
        line-height: 120%;
        font-weight: bold;
        font-family: var(--tc-ag-font-family);
        margin-bottom: 20px;
    }
    h3 {
        font-size: 40px;
        line-height: 120%;
        font-weight: bold;
        font-family: var(--tc-ag-font-family);
        margin-bottom: 20px;
    }
    h4 {
        font-size: 32px;
        line-height: 120%;
        font-weight: bold;
        font-family: var(--tc-ag-font-family);
        margin-bottom: 20px;
    }
}