.btn-primary {
    --bs-btn-color: var(--tc-neutral-0);
    --bs-btn-bg: var(--tc-theme-primary);
    --bs-btn-border-color: var(--tc-theme-primary);
    --bs-btn-hover-color: var(--tc-neutral-0);
    --bs-btn-hover-bg: var(--tc-theme-primary-dark);
    --bs-btn-hover-border-color: var(--tc-theme-primary-dark);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: var(--tc-neutral-0);
    --bs-btn-active-bg: var(--tc-theme-primary-dark);
    --bs-btn-active-border-color: var(--tc-theme-primary-dark);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--tc-neutral-0);
    --bs-btn-disabled-bg: var(--tc-theme-primary);
    --bs-btn-disabled-border-color: var(--tc-theme-primary);
}
/*Scroll to top*/
.btn-scroll-top {
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    display: block;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0.1rem rgba(227, 229, 233, 0.25);
    z-index: 1010;
    opacity: 0;
    visibility: hidden;
    transform: translateY(0.75rem);
    transition:
        all 0.2s linear,
        margin-right 0s;
    background-color: var(--tc-neutral-0);
    &:after {
        position: absolute;
        font-family: 'bootstrap-icons' !important;
        content: '\F145';
        text-align: center;
        line-height: 3rem;
        font-size: 1.5rem;
        color: var(--tc-theme-primary);
        left: 0;
        top: 0;
        height: 3rem;
        width: 3rem;
        cursor: pointer;
        display: block;
        z-index: 1;
        transition: all 0.2s linear;
    }
    &.active-progress {
        visibility: visible;
        transform: translateY(0);
        @media (min-width: 320px) {
            opacity: 1;
        }
    }
    & svg path {
        fill: none;
    }
    & svg.progress-square path {
        stroke: var(--tc-theme-primary);
        stroke-width: 2;
        box-sizing: border-box;
        transition: all 0.2s linear;
    }
}
.btn-white {
    background-color: var(--tc-theme-main);
    padding: 17px 30px;
    border-radius: 0px;
    color: var(--tc-neutral-950);
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    svg {
        stroke: var(--tc-primary-500);
        margin-left: 10px;
    }
    &:hover {
        background-color: var(--tc-neutral-300);
    }
}
.btn-quote {
    background-color: var(--tc-theme-main);
    padding: 17px 18px;
    border-radius: 0px;
    color: var(--tc-neutral-950);
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: var(--tc-body-font-family);
    svg {
        margin-right: 10px;
    }
    &:hover {
        background-color: var(--tc-neutral-300);
    }
}
.btn-primary {
    background-color: var(--tc-primary-500);
    color: var(--tc-theme-main);
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 999px;
    padding: 18px 24px;
    svg {
        stroke: var(--tc-theme-main);
        margin-left: 5px;
    }
}
.btn-primary-md {
    background-color: var(--tc-primary-500);
    color: var(--tc-theme-main);
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 999px;
    padding: 18px 24px;
    svg {
        fill: var(--tc-theme-main);
        margin-left: 5px;
    }
    &:hover {
        background-color: var(--tc-primary-1000);
        color: var(--tc-theme-main);
    }
}
.btn-white-md {
    background-color: var(--tc-theme-main);
    color: var(--tc-secondery-500);
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 999px;
    padding: 18px 24px;
    svg {
        fill: var(--tc-secondery-500);
        margin-left: 5px;
    }
    &:hover {
        background-color: var(--tc-primary-1000);
        color: var(--tc-theme-main);
        svg {
            fill: var(--tc-theme-main);
        }
    }
}
.btn-primary-square {
    background-color: var(--tc-primary-500);
    color: var(--tc-theme-main);
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 0px;
    padding: 18px 24px;
    svg {
        stroke: var(--tc-theme-main);
        margin-left: 5px;
    }
    &:hover {
        background-color: var(--tc-graay-500);
    }
}
.btn-primary-square-md {
    background-color: var(--tc-primary-500);
    color: var(--tc-theme-main);
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 10px;
    padding: 13px 24px;
    svg {
        stroke: var(--tc-theme-main);
        margin-left: 5px;
    }
    &:hover {
        background-color: var(--tc-graay-500);
    }
}
.btn-primary-square-2 {
    background-color: var(--tc-theme-primary);
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 5px;
    padding: 15px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    svg,
    img {
        stroke: var(--tc-theme-main);
        margin-left: 5px;
    }
    &:hover {
        background-color: var(--tc-graay-500);
    }
}
.btn-primary-square-3 {
    background-color: var(--tc-primary-500);
    color: var(--tc-theme-main);
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    border-radius: 5px;
    padding: 18px 24px;
    font-family: var(--tc-body-font-family);
    text-transform: uppercase;
    svg {
        fill: var(--tc-theme-main);
        margin-left: 5px;
    }
    &:hover {
        background-color: var(--tc-graay-500);
    }
}
.btn-primary-square-full {
    background-color: var(--tc-primary-500);
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 5px;
    padding: 12px 24px;
    width: 100%;
    font-family: var(--tc-body-font-family);
    text-transform: uppercase;
    svg {
        fill: var(--tc-theme-main);
        margin-left: 5px;
    }
    &:hover {
        background-color: var(--tc-graay-500);
    }
}
.btn-primary-square-2-md {
    background-color: var(--tc-theme-primary);
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 5px;
    padding: 11px 30px 10px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    svg,
    img {
        stroke: var(--tc-theme-main);
        margin-left: 5px;
    }
    &:hover {
        background-color: var(--tc-graay-500);
    }
}
.btn-primary-square-border {
    background-color: var(--tc-theme-main);
    color: var(--tc-dark-950);
    border: 1px solid var(--tc-theme-primary);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 5px;
    padding: 11px 30px 10px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    svg,
    img {
        stroke: var(--tc-theme-main);
        margin-left: 5px;
    }
    &:hover {
        background-color: var(--tc-graay-500);
    }
}
.btn-border-white {
    background-color: transparent;
    color: var(--tc-theme-main);
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 999px;
    padding: 18px 24px;
    border: 1px solid var(--tc-theme-main);
    svg {
        stroke: var(--tc-theme-main);
        margin-left: 5px;
    }
    &:hover {
        border-color: var(--tc-primary-500);
        color: var(--tc-primary-500);
        svg {
            stroke: var(--tc-primary-500);
        }
    }
}
.btn-border-6 {
    background-color: transparent;
    color: var(--tc-primary-500);
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 999px;
    padding: 18px 24px;
    border: 1px solid var(--tc-theme-bg-6);
    svg {
        stroke: var(--tc-primary-500);
        margin-left: 5px;
    }
    &:hover {
        border-color: var(--tc-primary-500);
        color: var(--tc-primary-500);
        svg {
            stroke: var(--tc-primary-500);
        }
    }
}
.btn-border-gray-600 {
    background-color: transparent;
    color: var(--tc-neutral-1200);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 999px;
    padding: 13px 30px;
    border: 1px solid var(--tc-graay-600);
    text-transform: uppercase;
    svg {
        stroke: var(--tc-neutral-1200);
        margin-left: 5px;
        margin-top: -3px;
    }
    &:hover {
        border-color: var(--tc-primary-500);
        color: var(--tc-primary-500);
        svg {
            stroke: var(--tc-primary-500);
        }
    }
}
.btn-border-primary-500 {
    background-color: transparent;
    color: var(--tc-primary-500);
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    border-radius: 999px;
    padding: 18px 24px;
    border: 1px solid var(--tc-primary-500);
    svg {
        stroke: var(--tc-primary-500);
        margin-left: 5px;
    }
    &:hover {
        border-color: var(--tc-primary-500);
        color: var(--tc-theme-main);
        background-color: var(--tc-primary-500);
        svg {
            stroke: var(--tc-theme-main);
        }
    }
}
.btn-linear {
    background-image: linear-gradient(to right, #1a1a1c, #00509d);
    border-radius: 0px;
    border: 0px;
    padding: 14px 20px 14px 30px;
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    font-family: var(--tc-ag-font-family);
    text-transform: uppercase;
    svg {
        stroke: var(--tc-theme-main);
        height: 10px;
        margin-left: 3px;
        margin-top: -2px;
    }
}
.btn-border-950 {
    padding: 20px 60px;
    border: 1px solid var(--tc-dark-950);
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    font-family: var(--tc-ag-font-family);
    &:hover {
        background-color: var(--tc-dark-950);
        color: var(--tc-theme-main);
    }
}
.btn-secondery-500 {
    padding: 12px 20px;
    border-radius: 8px;
    text-transform: none;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    font-family: var(--tc-body-font-family);
    background-color: var(--tc-secondery-500);
    color: var(--tc-theme-main);
    &:hover {
        background-color: var(--tc-dark-950);
        color: var(--tc-theme-main);
    }
}
.btn-default {
    padding: 20px 30px;
    background-color: #174c6818;
    border-radius: 4px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    font-family: var(--tc-ag-font-family);
    &:hover {
        background-color: var(--tc-secondery-400);
        color: var(--tc-theme-main);
    }
}
.btn-primary-home {
    background-color: var(--tc-primary-home);
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 5px;
    padding: 11px 30px 10px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    transition-duration: 0.4s;
    svg,
    img {
        stroke: var(--tc-theme-main);
        margin-left: 5px;
    }
    &:hover {
        opacity: 0.9;
        transition-duration: 0.4s;
        background-color: var(--tc-primary-home);
        color: var(--tc-theme-main);
    }
}
.btn-primary-home-square {
    background-color: var(--tc-primary-home);
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    border-radius: 0px;
    padding: 11px 20px 10px 20px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    transition-duration: 0.4s;
    svg,
    img {
        stroke: var(--tc-theme-main);
    }
    &:hover {
        opacity: 0.9;
        transition-duration: 0.4s;
    }
}
.btn-white-sm {
    padding: 8px 12px;
    background-color: var(--tc-theme-main);
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: var(--tc-primary-home);
    font-family: var(--tc-rubik-font-family);
}
.btn-filter {
    padding: 16px 24px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    font-family: var(--tc-ag-font-family);
    border-radius: 0px;
    color: var(--tc-theme-main);
    text-transform: uppercase;
    &:hover,
    &.active {
        background-color: #ffffffde;
        color: var(--tc-dark-950);
    }
}
.btn-filter-primary {
    padding: 16px 24px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    font-family: var(--tc-ag-font-family);
    border-radius: 0px;
    color: var(--tc-dark-950);
    text-transform: uppercase;
    &:hover,
    &.active {
        background-color: var(--tc-primary-500);
        color: var(--tc-theme-main);
    }
}
.btn-black {
    padding: 16px 24px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: var(--tc-theme-main);
    text-transform: uppercase;
    background-color: var(--tc-dark-950);
    border-radius: 0px;
    &:hover {
        background-color: var(--tc-neutral-700);
        color: var(--tc-theme-main);
    }
}
.btn-black-rounded {
    padding: 18px 44px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: var(--tc-theme-main);
    text-transform: none;
    background-color: var(--tc-neutral-2400);
    border-radius: 10px;
    font-family: var(--tc-ag-font-family);
    &:hover {
        background-color: var(--tc-neutral-700);
        color: var(--tc-theme-main);
    }
}
.btn-get-in-touch {
    padding: 26px 48px;
    background-color: var(--tc-primary-500);
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    font-family: var(--tc-body-font-family);
    border-radius: 0px 4px 4px 0px;
    color: var(--tc-theme-main);
    margin-right: -1px;
}
.btn-linear-01 {
    background-image: linear-gradient(to right, #b91202, #b9110200);
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 999px;
    padding: 15px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    border: 1px solid #ffffff85;
    img {
        display: inline-block;
        vertical-align: middle;
        margin: -2px 0px 0px 5px;
    }
    &:hover {
        border-color: var(--tc-primary-home);
        color: var(--tc-theme-main);
    }
}
.btn-linear-02 {
    background-image: linear-gradient(to right, #4aa4f0, #9e3dde);
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 8px;
    padding: 14px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    border: 0px;
    img {
        display: inline-block;
        vertical-align: middle;
        margin: -2px 0px 0px 5px;
    }
    &:hover {
        color: var(--tc-theme-main);
    }
}
.btn-linear-03 {
    background-image: linear-gradient(to right, #1ab69d, #31b978);
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 8px;
    padding: 14px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    border: 0px;
    img {
        display: inline-block;
        vertical-align: middle;
        margin: -2px 0px 0px 5px;
    }
    &:hover {
        color: var(--tc-theme-main);
    }
}
.btn-linear-02-md {
    background-image: linear-gradient(to right, #4aa4f0, #9e3dde);
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 0px;
    padding: 11px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    border: 0px;
    img {
        display: inline-block;
        vertical-align: middle;
        margin: -2px 0px 0px 5px;
    }
    &:hover {
        color: var(--tc-theme-main);
    }
}
.btn-primary-1000 {
    background-color: var(--tc-primary-1000);
    color: var(--tc-neutral-1000);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 999px;
    padding: 16px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    img {
        display: inline-block;
        vertical-align: middle;
        margin: -2px 0px 0px 5px;
    }
    svg {
        fill: var(--tc-neutral-1000);
        margin-left: 5px;
    }
    &:hover {
        color: var(--tc-theme-main);
        background-color: var(--tc-primary-600);
        svg {
            fill: var(--tc-theme-main);
        }
    }
}
.btn-primary-1400 {
    background-color: var(--tc-primary-1400);
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 999px;
    padding: 16px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    img {
        display: inline-block;
        vertical-align: middle;
        margin: -2px 0px 0px 5px;
    }
    svg {
        fill: var(--tc-theme-main);
        margin-left: 5px;
        margin-top: -3px;
    }
    &:hover {
        color: var(--tc-theme-main);
        background-color: var(--tc-primary-600);
        svg {
            fill: var(--tc-theme-main);
        }
    }
}
.btn-primary-1000-md {
    background-color: var(--tc-primary-1000);
    color: var(--tc-neutral-1000);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 999px;
    padding: 13px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    img {
        display: inline-block;
        vertical-align: middle;
        margin: -2px 0px 0px 5px;
    }
    svg {
        stroke: var(--tc-neutral-1000);
        margin-left: 5px;
        margin-top: -2px;
    }
    &:hover {
        color: var(--tc-theme-main);
        background-color: var(--tc-primary-600);
        svg {
            stroke: var(--tc-theme-main);
        }
    }
}
.btn-primary-home {
    background-color: var(--tc-primary-home);
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    border-radius: 999px;
    padding: 15px 30px;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    border: 1px solid #ffffff85;
    img {
        display: inline-block;
        vertical-align: middle;
        margin: -2px 0px 0px 5px;
    }
    &:hover {
        border-color: var(--tc-primary-home);
        color: var(--tc-theme-main);
    }
}
.btn-submit {
    background-color: var(--tc-primary-home);
    border-radius: 20px;
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 32px;
    font-weight: bold;
    font-family: var(--tc-ag-font-family);
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    padding: 15px 30px;
    &:hover {
        background-color: var(--tc-primary-home-2);
        color: var(--tc-theme-main);
    }
}
.btn-view-all {
    font-size: 20px;
    line-height: 30px;
    color: #ffffffd7;
    font-weight: 600;
    font-family: var(--tc-body-font-family);
    span {
        display: inline-block;
        height: 43px;
        width: 43px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        margin-left: 12px;
        background-color: var(--tc-primary-500);
        svg {
            stroke: var(--tc-theme-main);
        }
    }
}
.btn-border-small {
    border-radius: 8px;
    background:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(to right, #9e3dde, #4aa4f0) border-box;
    border: 1px solid transparent;
    padding: 10px;
    color: var(--tc-dark-950);
    font-size: 16px;
    line-height: 130%;
    font-weight: bold;
    font-family: var(--tc-ag-font-family);
    text-transform: uppercase;
    svg {
        margin-left: 10px;
        margin-top: -1px;
        stroke: var(--tc-dark-950);
    }
    &:hover {
        background:
            linear-gradient(var(--tc-dark-950) 0 0) padding-box,
            linear-gradient(to right, #9e3dde, #4aa4f0) border-box;
        border: 1px solid transparent;
        color: var(--tc-theme-main);
        svg {
            stroke: var(--tc-theme-main);
        }
    }
}
.btn-icon-dark {
    svg {
        stroke: var(--tc-dark-950);
        fill: var(--tc-dark-950);
        margin-top: -3px;
    }
}
.btn-primary-500 {
    background-color: var(--tc-primary-500);
    padding: 12px 20px;
    color: var(--tc-theme-main);
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    font-family: var(--tc-body-font-family);
    border-radius: 0px;
    svg {
        margin-left: 10px;
        stroke: var(--tc-theme-main);
    }
    &:hover {
        background-color: var(--tc-primary-1000);
        color: var(--tc-theme-main);
    }
}
.btn-primary-500-rounded {
    background-color: var(--tc-primary-500);
    padding: 15px 30px;
    color: var(--tc-theme-main);
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: var(--tc-ag-font-family);
    border-radius: 10px;
    svg {
        margin-left: 10px;
        stroke: var(--tc-theme-main);
    }
    &:hover {
        background-color: var(--tc-primary-1000);
        color: var(--tc-theme-main);
    }
}
.btn-white-border {
    border: 1px solid var(--tc-theme-main);
    background-color: transparent;
    padding: 12px 20px;
    color: var(--tc-theme-main);
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    font-family: var(--tc-body-font-family);
    border-radius: 0px;
    svg {
        margin-left: 10px;
        stroke: var(--tc-theme-main);
    }
    &:hover {
        border-color: var(--tc-primary-500);
        color: var(--tc-primary-500);
        svg {
            stroke: var(--tc-primary-500);
        }
    }
}
.btn-play-2 {
    height: 60px;
    width: 60px;
    background-color: var(--tc-primary-500);
    line-height: 44px;
    border-radius: 0px;
    text-align: center;
    img {
        display: inline-block;
        vertical-align: middle;
    }
    &:hover {
        background-color: var(--tc-primary-1000);
    }
}
.btn-play-5 {
    height: 72px;
    width: 72px;
    padding: 0px;
    position: absolute;
    top: -3%;
    bottom: 0px;
    left: -14%;
    right: 0px;
    margin: auto auto auto auto;
    z-index: 123;
    background-color: var(--tc-theme-main);
    border-radius: 12px;
    text-align: center;
    line-height: 70px;
    svg {
        fill: var(--tc-dark-950);
    }
    &:hover {
        background-color: var(--tc-primary-500);
        svg {
            fill: var(--tc-theme-main);
        }
    }
}
.btn-inter {
    text-transform: none;
    font-size: 18px;
    line-height: 100%;
    font-weight: bold;
    font-family: var(--tc-inter-font-family);
    padding: 19px 35px;
}
.btn-tag {
    color: var(--tc-dark-950);
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
    font-family: var(--tc-rubik-font-family);
    padding: 11px 18px;
    border: 1px solid var(--tc-theme-border-10);
    border-radius: 5px;
    display: inline-block;
    &:hover {
        background-image: linear-gradient(to right, #1ab69d, #31b978);
        color: var(--tc-theme-main);
    }
}
.btn-primary-home-rounded {
    border-radius: 5px;
    background-color: var(--tc-primary-home);
    color: var(--tc-theme-main);
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    font-family: var(--tc-ag-font-family);
    padding: 11px 30px;
    &:hover {
        background-color: var(--tc-neutral-1200);
        color: var(--tc-theme-main);
    }
}
