.footer-style-green {
    svg {
        fill: var(--tc-primary-1500);
    }
    .hover-effect svg {
        fill: var(--tc-primary-1500);
    }
    .list-socials {
        a {
            &:hover {
                svg {
                    fill: var(--tc-primary-1500);
                }
            }
        }
    }
    .form-newsletter .btn-newsletter {
        background-color: var(--tc-primary-1500);
    }
    .footer-bottom {
        border-top: 1px solid #2ab8821c;
        padding-top: 30px;
        padding-bottom: 50px;
    }
}
.blog-detail {
    p, strong, h6 {
        margin-bottom: 30px;
        color: var(--tc-neutral-2300);
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        font-family: var(--tc-body-font-family);
    }
    strong, h6 {
        color: var(--tc-neutral-2400);
        font-weight: bold;
    }
    img {
        margin-bottom: 30px;
    }
}
.box-pagination {
    border-top: 1px solid var(--tc-neutral-2400);
    border-bottom: 1px solid #2222221a;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 80px;
}
.recent-news-list-md {
    padding: 0px;
    margin: 0px;
    list-style: none;
    li {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0px;
        }
        .news-image {
            width: 90px;
            min-width: 90px;
            margin-right: 15px;
            img {
                display: block;
                border-radius: 5px;
            }
        }
        .news-info {
            .news-postdate {
                margin-bottom: 3px;
                svg {
                    margin-right: 3px;
                    margin-top: -2px;
                    fill: var(--tc-graay-1300);
                }
            }
            .news-link {
                color: var(--tc-dark-950);
                &:hover {
                    color: var(--tc-primary-1000);
                }
            }
        }
    }
}
.blog-main-content {
    display: flex;
    margin: 0px -15px;
    .blog-content-left {
        width: 72%;
        padding: 0px 15px;
    }
    .blog-content-right {
        padding: 0px 15px;
        width: 28%;
    }
}
.content-sidebar {
    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        li {
            a {
                font-size: 16px;
                line-height: 40px;
                font-weight: 500;
                font-family: var(--tc-body-font-family);
                color: var(--tc-dark-950);
                span {
                    color: var(--tc-graay-1400);
                }
                &:hover {
                    color: var(--tc-primary-1000);
                    span {
                        color: var(--tc-primary-1000);
                    }
                }
            }
        }
    }
}
.form-search-sidebar {
    position: relative;
    .form-control {
        border: 1px solid var(--tc-theme-border-11);
        box-shadow: var(--tc-shadow-1);
        height: 60px;
        width: 100%;
    }
    .btn-search {
        position: absolute;
        top: 0px;
        right: 0px;
        background-image: linear-gradient(to right, #1AB69D, #31B978);
        border: 0px;
        cursor: pointer;
        text-align: center;
        width: 65px;
        height: 60px;
        line-height: 48px;
        border-radius: 0px 5px 5px 0px;
        svg {
            fill: var(--tc-theme-main);
        }
    }
}
.list-feedbacks {
    padding: 0px;
    margin: 0px;
    list-style: none;
    li {
        .item-feedback {
            display: flex;
            align-items: flex-start;
            padding: 40px 0px 50px 0px;
            border-top: 1px solid var(--tc-theme-border-12);
            .image-feedback {
                width: 80px;
                min-width: 80px;
                margin-right: 30px;
                img {
                    height: 80px;
                    width: 80px;
                    border-radius: 50%;
                }
            }
            .info-feedback {
                width: 100%;
                .name-user {
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--tc-neutral-2400);
                    font-weight: 700;
                    margin-bottom: 20px;
                    span {
                        font-weight: 400;
                        color: var(--tc-neutral-2300);
                    }
                }
                .comment-text {
                    margin-bottom: 20px;
                }
                .reply {
                    font-size: 14px;
                    line-height: 18px;
                    color: var(--tc-neutral-2400);
                    font-weight: 400;
                    font-family: var(--tc-ag-font-family);
                    svg {
                        margin-right: 10px;
                        fill: var(--tc-neutral-2400);
                    }
                }
            }
        }
        ul {
            padding-left: 110px;
        }
    }
}
.box-form-feedback {
    position: relative;
}
.form-feedback {
    padding-top: 40px;
    border-top: 1px solid var(--tc-theme-border-13);
    .form-group {
        margin-bottom: 20px;
    }
    .form-control {
        border: 1px solid #2222222c;
        height: 54px;
        border-radius: 0px;
    }
    textarea.form-control {
        height: auto;
    }
}
.box-blog-single-banner {
    .box-faq-single-banner-inner {
        &::before {
            background: url(../imgs/pages/blog/icon-1.png) no-repeat center;
        }
        &::after {
            background: url(../imgs/pages/blog/icon-2.png) no-repeat center;
        }
    }
}
.container-slide {
    .swiper-slide {
        text-align: center;
    }
}
.box-paginations {
    display: inline-block;
    width: 100%;
    text-align: right;
    padding: 50px 0px;
    nav {
        display: inline-block;
    }
    .pagination {
        li {
            padding: 0px 5px;
            a {
                display: inline-block;
                border-radius: 3px !important;
                background-image: linear-gradient(to right, #fff, #fff);
                font-size: 20px;
                color: var(--tc-graay-1000);
                line-height: 30px;
                font-weight: 600;
                font-family: var(--tc-ag-font-family);
                padding: 10px 22px;
                border: 1px solid var(--tc-theme-border-10);
                svg {
                    fill: var(--tc-graay-1000);
                }
                &:hover {
                    background-image: linear-gradient(to right, #1AB69D, #31B978);
                    color: var(--tc-theme-main);
                    svg {
                        fill: var(--tc-theme-main);
                    }
                }
            }
        }
    }
}