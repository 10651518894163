.box-banner-2 {
    padding: 70px 0px 0px 0px;
    background-color: var(--tc-theme-bg-1);
}
.banner-small-title-black {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    color: var(--tc-dark-950);
    font-family: var(--tc-ag-font-family);
    text-transform: uppercase;
    position: relative;
    padding-right: 70px;
    margin-bottom: 20px;
    display: inline-block;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        height: 2px;
        background-color: var(--tc-theme-primary);
        width: 60px;
    }
}
.banner-small-title-white {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    color: var(--tc-theme-main);
    font-family: var(--tc-ag-font-family);
    text-transform: uppercase;
    position: relative;
    padding-right: 70px;
    margin-bottom: 20px;
    display: inline-block;
    &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        height: 2px;
        background-color: var(--tc-theme-main);
        width: 60px;
    }
}
.title-banner-black {
    font-size: 68px;
    line-height: 115%;
    font-weight: 700;
    color: var(--tc-dark-950);
    font-family: var(--tc-ag-font-family);
}
.box-need-help {
    padding-left: 70px;
    background: url(../imgs/pages/home2/help.png) no-repeat left center;
    p {
        margin: 0px;
    }
}
.header-style-2 {
    background-color: var(--tc-theme-bg-1);
}
.list-tick-col {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    list-style: none;
    padding: 0px;
    flex-wrap: wrap;
    li {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        color: var(--tc-theme-text-2);
        margin-bottom: 20px;
        width: 100%;
        svg {
            margin-right: 6px;
        }
    }
}
.box-about-us {
    padding: 120px 0px 90px 0px;
    background-color: var(--tc-theme-bg-2);
    border-top: 1px solid var(--tc-secondery-400);
}
.happy-customers {
    border: 1px solid var(--tc-theme-primary);
    border-radius: 5px;
    padding: 13px 32px;
    display: inline-block;
    max-width: 273px;
    margin-right: 30px;
}
.box-happy-customers {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0px 40px 0px 0px;
    flex-wrap: wrap;
    position: relative;
    .happy-customers {
        margin-left: 30px;
    }
    img {
        display: block;
    }
    .box-happy-img-1 {
        position: absolute;
        left: 0px;
        z-index: 1;
        text-align: left;
        img {
            width: 100%;
            margin-bottom: 25px;
        }
    }
    .box-happy-img-2 {
        position: relative;
        z-index: 2;
        img {
            width: 100%;
        }
    }
}
.box-projects-home2 {
    padding: 90px 0px;
}
.box-list-projects {
    margin: 0px -10px;
    display: flex;
    flex-wrap: wrap;
    .item-project {
        width: 20%;
        padding: 0px 10px;
        img {
            display: block;
            width: 100%;
        }
    }
}
.box-services {
    padding: 90px 0px;
    background-color: var(--tc-theme-bg-1);
}
.box-services-lists {
    display: flex;
    align-items: flex-start;
    width: 100%;
    border: 1px solid var(--tc-theme-border-4);
    border-left: 1px solid var(--tc-dark-950);
    position: relative;
    &::before {
        content: "";
        height: 1px;
        background-color: var(--tc-dark-950);
        top: -1px;
        left: 0px;
        z-index: 2;
        width: 50%;
        position: absolute;
        display: none;
    }
    &::after {
        content: "";
        height: 1px;
        background-color: var(--tc-dark-950);
        bottom: -1px;
        left: 0px;
        z-index: 2;
        width: 50%;
        position: absolute;
        display: none;
    }
    .service-right {
        padding: 55px 51px 55px 51px;
        width: 50%;
    }
    .service-left {
        width: 50%;
    }
}
.head-service {
    padding: 30px 0px 30px 30px;
}
.service-item-list {
    width: 100%;
    border-top: 1px solid var(--tc-dark-950);
    border-bottom: 1px solid var(--tc-dark-950);
    border-left: 0px;
    border-bottom: 0px;
    .item-service {
        padding: 30px;
        border-bottom: 1px solid var(--tc-dark-950);
        border-right: 1px solid var(--tc-dark-950);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .title-service {
            font-size: 32px;
            line-height: 120%;
            font-weight: bold;
            font-family: var(--tc-ag-font-family);
            text-transform: uppercase;
            color: var(--tc-dark-950);
            max-width: 60%;
        }
        .link-read-more {
            display: inline-block;
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            background-color: var(--tc-secondery-400);
            border-radius: 50%;
            svg {
                stroke: var(--tc-theme-main);
            }
        }
        .desc-tab-service {
            max-width: 37%;
            display: none;
            color: var(--tc-dark-950);
            margin-bottom: 0px;
        }
        &:last-child {
            border-bottom: 0px;
        }
        &.active {
            border-right: 0px solid var(--tc-dark-950);
            .title-service {
                text-decoration: underline;
            }
            .link-read-more {
                display: none;
            }
            .desc-tab-service {
                display: block;
            }
        }
    }
}
.box-testimonial-2 {
    padding: 90px 0px;
    background-color: var(--tc-dark-950);
    background-image: url(../imgs/pages/home2/bg-testimonial.png);
    background-repeat: no-repeat;
    background-position: center bottom;
}
.box-consultants {
    padding: 90px 0px;
    background-image: url(../imgs/pages/home2/bg-team.png);
    background-repeat: no-repeat;
    background-position: right bottom -260px;
}
.consultant-social {
    a {
        display: inline-block;
        margin-right: 32px;
        position: relative;
        transition: all .3s ease-in-out;
        top: 0px;
        &:last-child {
            margin-right: 0px;
        }
        &:hover {
            top: -3px;
        }
    }
}
.block-video {
    height: 870px;
    background: url(../imgs/pages/home2/img-video.png) no-repeat center;
    background-size: cover;
    position: relative;
    &::before {
        content: "";
        z-index: 1;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: url(../imgs/pages/home2/bg-video.png) no-repeat left center;
        background-size: cover;
    }
    .btn-play {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 2;
        background-color: var(--tc-primary-500);
        height: 150px;
        width: 150px;
        border-radius: 50%;
        font-size: 32px;
        line-height: 120%;
        font-weight: 700;
        font-family: var(--tc-ag-font-family);
        color: var(--tc-theme-main);
        line-height: 140px;
        text-transform: capitalize;
        text-align: center;
        margin: auto;
        transition: all .3s ease-in-out;
        &:hover {
            top: -10px;
        }
    }
}
.swiper-button-prev-style-3, .swiper-button-next-style-3 {
    background-color: var(--tc-dark-950);
    svg {
        stroke: var(--tc-theme-secondary);
    }
    &:hover {
        background-color: var(--tc-theme-main);
        border-color: var(--tc-theme-main);
        svg {
            stroke: var(--tc-dark-950);
        }
    }
}
.light-mode {display: inline-block;}
.dark-mode {display: none;}
.box-button-change-package {
    .btn {
        margin-right: 30px;
        &:last-child {
            margin-right: 0px;
        }
        &.btn-active {
            background-color: var(--tc-secondery-400);
            color: var(--tc-theme-main);
        }
    }
}
.package-item {
    display: none;
}
.box-latest-blog-2 {
    padding: 110px 0px;
    background-color: var(--tc-theme-secondary);
}
.top-footer {
    position: relative;
    .box-contact-phone {
        top: -90px;
        position: relative;
        background-color: var(--tc-theme-main);
        padding: 60px 118px;
        box-shadow: var(--tc-shadow-1);
    }
}
.footer-2 {
    margin-top: 150px;
    background-color: var(--tc-dark-950);
    background-image: none;
    .box-contact-phone {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .section-footer {
        padding: 0px 0px 60px 0px;
    }
    .icon-contact {
        height: 60px;
        width: 60px;
        min-width: 60px;
        background-color: var(--tc-theme-secondary);
        border-radius: 50%;
        line-height: 60px;
        text-align: center;
        margin-right: 15px;
        svg {
            fill: var(--tc-secondery-600);
        }
    }
    .list-socials {
        .share {
            background-color: #d0d2d618;
            border-radius: 0px;
            border: 0px;
            &:hover {
                background-color: var(--tc-primary-home);
            }
        }
    }
    .text-footer {
        border-bottom: 0px;
        font-family: var(--tc-ag-font-family);
    }
    .form-newsletter .btn-newsletter {
        background-color: var(--tc-secondery-500);
    }
}