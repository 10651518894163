.box-banner-8 {
    padding: 120px 0px;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        width: 80px;
        height: 80px;
        top: 31%;
        right: 54px;
        background-image: url(../imgs/pages/home8/setup.png);
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
        animation: rotate 2s linear infinite;
    }
    &::after {
        content: '';
        position: absolute;
        width: 80px;
        height: 80px;
        top: 51%;
        left: 54px;
        background-image: url(../imgs/pages/home8/setup.png);
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
        animation: rotate 2s linear infinite;
    }
    .container {
        position: relative;
        z-index: 2;
    }
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.box-about-us-8 {
    padding: 120px 0px;
    background-color: var(--tc-dark-950);
}
.box-services-8 {
    padding: 120px 0px;
    background-color: var(--tc-primary-1100);
    background-image: url(../imgs/pages/home8/bg-service.png);
    background-repeat: no-repeat;
    background-position: center;
}
.box-project-8 {
    background-image: linear-gradient(to right, #4aa4f0, #9e3dde);
    padding: 120px 0px;
}
.line-theme-main {
    color: var(--tc-theme-main);
    &::before {
        background-color: var(--tc-theme-main);
    }
    &::after {
        background-color: var(--tc-theme-main);
    }
}
.rd8 {
    border-radius: 8px;
}
.box-team-members {
    padding: 120px 0px;
    .swiper-button-prev-style-3,
    .swiper-button-next-style-3 {
        background-image: linear-gradient(to right, #9e3dde34, #4aa5f033);
        background-color: transparent;
        border: 0px;
        svg {
            stroke: var(--tc-dark-950);
        }
        &:hover {
            background-image: linear-gradient(to right, #9e3dde, #4aa5f0);
            svg {
                stroke: var(--tc-theme-main);
            }
        }
    }
}
.line-dark-950 {
    &::before {
        background-color: var(--tc-dark-950);
    }
}
.box-team-members {
    background-image: linear-gradient(to right, #4aa5f02a, #9e3dde2d);
}
.box-team-members-inner {
    background-image: url(../imgs/pages/home8/bg-team.png);
    background-repeat: no-repeat;
    background-position: top left;
}
.box-faqs-8 {
    padding: 120px 0px;
    position: relative;
    .block-faqs {
        margin-bottom: 40px;
    }
    .accordion-item {
        box-shadow: none;
        border: 0px;
        border-top: 1px solid #00040756;
        margin-bottom: 0px;
        &:last-child {
            border-bottom: 1px solid #00040756;
        }
    }
    .accordion-button,
    .accordion-body {
        padding-left: 0px;
        padding-right: 0px;
    }
    .accordion-button {
        padding-top: 30px;
        padding-bottom: 30px;
        font-family: var(--tc-ag-font-family);
    }
    .accordion-button:focus {
        box-shadow: none;
    }
    .accordion-button::after {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 28px;
        background-color: var(--tc-graay-100);
        background-position: center;
        background-size: 14px;
    }
}
.box-faqs-8-inner {
    position: relative;
    overflow: hidden;
    .box-faqs-8-bg {
        background-image: url(../imgs/pages/home8/img-faq.png);
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 809px;
        width: 100%;
        max-width: 50%;
        z-index: 12;
        &::before {
            content: '';
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            width: 325px;
            height: 721px;
            background-image: url(../imgs/pages/home8/bg-faq.png);
            background-repeat: no-repeat;
            background-position: left center;
            background-size: cover;
        }
    }
    &:after {
        content: '';
        position: absolute;
        width: 106px;
        height: 110px;
        background-image: url(../imgs/pages/home8/icon1.png);
        background-repeat: no-repeat;
        background-position: center;
        top: 140px;
        right: 80px;
    }
    &::before {
        content: '';
        position: absolute;
        height: 133px;
        width: 712px;
        background-image: url(../imgs/pages/home8/icon2.png);
        background-repeat: no-repeat;
        background-position: left center;
        bottom: 40px;
        right: 0px;
    }
}
.box-testimonial-8 {
    padding: 120px 0px;
    background-color: var(--tc-dark-950);
    .card-testimonials-6 {
        background:
            linear-gradient(var(--tc-dark-950) 0 0) padding-box,
            linear-gradient(to right, #9e3dde, #4aa4f0) border-box;
        border: 4px solid transparent;
        .card-author {
            .author-info {
                p {
                    color: #eeeeeed5;
                }
            }
        }
    }
}
.box-testimonial-8 {
    .card-testimonials-6 {
        .card-author {
            .author-image {
                width: 60px;
                height: 60px;
                line-height: 50px;
                min-width: 60px;
                margin-right: 16px;
                img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                }
            }
        }
        .comment-text {
            margin-bottom: 32px;
        }
    }
    .box-slider-testimonials .swiper-slide {
        max-width: 580px;
    }
    .box-button-slider .swiper-button-prev,
    .box-button-slider .swiper-button-next {
        background-image: linear-gradient(to right, #4aa5f09c, #9e3dde9f);
    }
}
.top-footer-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-footer {
        position: relative;
        text-align: center;
        width: 50%;
    }
    .right-footer {
        position: relative;
        text-align: center;
        width: 50%;
        * {
            display: inline-block;
            padding: 0px 3px;
        }
    }
    .middle-footer {
        min-width: 260px;
        background-color: transparent;
        background-image: linear-gradient(to right, #4aa4f0, #9e3dde);
        padding: 40px 40px;
        text-align: center;
    }
}
.footer-style-7 {
    margin-top: 0px;
    .top-footer {
        background:
            linear-gradient(var(--tc-dark-950) 0 0) padding-box,
            linear-gradient(to right, #9e3dde, #4aa4f0) border-box;
        border-bottom: 1px solid transparent;
    }
    .section-footer {
        padding-top: 80px;
    }
    .list-socials {
        .share {
            border-radius: 50%;
            background:
                linear-gradient(var(--tc-dark-950) 0 0) padding-box,
                linear-gradient(to right, #9e3dde, #4aa4f0) border-box;
            border: 1px solid transparent;
            line-height: 42px;
            text-align: center;
            &:hover {
                background-image: linear-gradient(to right, #4aa5f09c, #9e3dde9f);
            }
        }
    }
}
.box-progress-gradient {
    .progress-bar {
        background-color: var(--tc-theme-bg-8);
    }
}
.line-gradient-01 {
    &::before {
        background-image: linear-gradient(to right, #9e3dde, #4aa5f0);
        background-color: transparent;
    }
}
.box-image-about-8 {
    position: relative;
    display: inline-block;
}
.image-circle {
    position: absolute;
    right: -110px;
    bottom: 0px;
    z-index: 2;
}

.progress-bar {
    transition: width 0.5s ease-in-out;
}

.box-progress-gradient {
    overflow: hidden;
}

.box-progress-gradient .progress-bar {
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
}

.box-progress-gradient .progress-bar.animate {
    transform: scaleX(1);
}

/* Add this CSS to animate the progress bar on scroll */
.box-progress-gradient.animate .progress-bar {
    animation: animateProgressBar 5s ease-in-out forwards;
}

@keyframes animateProgressBar {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}
