.list-none {
    list-style: none;
    padding: 0px;
    margin: 0px;
    li {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}
.box-banner-16 {
    background: url(../imgs/pages/home16/bg-banner.png) no-repeat right bottom;
    background-size: cover;
    .box-banner-16-inner {
        height: auto;
        padding: 0px;
        .banner {
            padding: 185px 0px 250px 0px;
            background: url(../imgs/pages/home16/banner.png) no-repeat right bottom;
        }
    }
    .btn-primary-square-md {
        font-size: 18px;
        line-height: 29px;
        svg {
            fill: var(--tc-theme-main);
        }
    }
}
.box-images-about-16 {
    display: flex;
    margin: 0px -15px;
    position: relative;
    padding-bottom: 30px;
    .image-about-1 {
        width: 55%;
        padding: 0px 15px;
        .card-number {
            position: absolute;
            bottom: 0px;
            right: 170px;
            padding: 18px 25px;
            background-color: var(--tc-primary-500);
            max-width: 278px;
            left: auto;
            .card-years {
                padding-right: 15px;
                min-width: 100px;
                h2 {
                    line-height: 44px;
                    color: var(--tc-theme-main);
                }
                span {
                    color: var(--tc-theme-main);
                }
            }
        }
    }
    .image-about-2 {
        width: 45%;
        padding: 0px 15px 135px 15px;
    }
}
.box-img-circle {
    position: absolute;
    right: 30px;
    bottom: 0px;
}
.box-process-16 {
    background-image: url(../imgs/pages/home16/bg-process.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: #0055ff33;
    }
    .container {
        position: relative;
        z-index: 2;
    }
    .row.none-pd {
        margin: 0px;
        .col-md-6 {
            padding: 0px;
        }
    }
}
.link-upper {
    color: var(--tc-theme-main);
    svg {
        stroke: var(--tc-theme-main);
        margin: -4px 0px 0px 5px;
    }
}
.box-project-16 {
    .card-project-2 {
        border-radius: 0px;
        .card-info {
            border-radius: 0px;
            background-color: #0055ffc9;
            background-image: none;
            .card-info-inner .info-bottom {
                background-color: transparent;
                .heading-lg {
                    color: var(--tc-theme-main);
                }
            }
        }
    }
}
.box-testimonials-16 {
    background-color: var(--tc-secondery-600);
    padding-bottom: 10px;
}
.box-button-slider-testimonials-16 {
    margin-top: 15px;
    .swiper-button-prev-style-6, .swiper-button-next-style-6 {
        background-color: var(--tc-secondery-500);
        border-radius: 100px;
        height: 40px;
        width: 48px;
        svg {
            stroke: var(--tc-theme-main);
        }
        &:hover {
            background-color: var(--tc-theme-main);
            svg {
                stroke: var(--tc-secondery-500);
            }
        }
    }
}
.num-review {
    position: absolute;
    bottom: -43px;
    left: 0px;
    right: 0px;
    z-index: 12;
    margin: auto;
}
.box-num-years {
    padding: 22px 14px;
    background-color: var(--tc-primary-500);
    min-width: 202px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 40px;
    right: 0px;
    z-index: 12;
    .box-left {
        font-size: 48px;
        line-height: 50px;
        font-weight: 700;
        color: var(--tc-theme-main);
        font-family: var(--tc-body-font-family);
        margin-right: 8px;
    }
    .box-right {
        font-size: 20px;
        line-height: 20px;
        font-weight: 300;
        font-family: var(--tc-body-font-family);
        color: var(--tc-theme-main);
    }
}
.box-faq-16 {
    padding-top: 120px;
    padding-bottom: 30px;
    .box-images-faq-inner {
        position: relative;
        margin: 0px 30px 0px 0px;
    }
}
.container-contact-section {
    display: flex;
    align-items: center;
    .contact-section-left, .contact-section-right {
        width: 50%;
    }
    .contact-section-left {
        height: 893px;
        background: url(../imgs/pages/home16/contact.png) no-repeat top center;
        background-size: cover;
    }
    .contact-section-right {
        .map {
            height: 599px;
            width: 100%;
            position: relative;
            iframe {
                width: 100% !important;
                height: 100% !important;
            }
        }
        .contact-info {
            background-color: var(--tc-primary-500);
            padding: 60px;
        }
    }
}
.box-blogs-16 {
    padding: 120px 0px;
    .card-blog-10 {
        border: 1px solid #e6eeffb2;
        border-radius: 0px;
        box-shadow: none;
        .card-info {
            .card-title {
                .btn-border-950 {
                    text-transform: none;
                    &:hover {
                        background-color: var(--tc-secondery-500);
                    }
                }
            }
        }
        .card-image {
            position: relative;
            .card-date {
                display: none;
            }
        }
        &:hover {
            .card-image {
                .card-date {
                    display: block;
                }
            }
        }
    }
}
.box-button-change-package-2 {
    margin-top: 30px;
    .btn-default {
        border-radius: 4px;
        padding: 12px 30px;
        font-size: 18px;
        line-height: 28px;
        background-color: var(--tc-primary-50);
        color: var(--tc-secondery-500);
        font-family: var(--tc-body-font-family);
        font-weight: 600;
        margin-right: 20px;
        &:hover {
            background-color: var(--tc-dark-950);
            color: var(--tc-theme-main);
        }
    }
}
.footer-style-15 {
    .footer-1 {
        background-color: var(--tc-theme-main);
    }
    .btn-newsletter {
        background-color: var(--tc-primary-500);
    }
    .list-socials a {
        background-color: transparent;
        border: 1px solid var(--tc-theme-main);
        background-image: none !important;
        svg {
            fill: var(--tc-theme-main);
        }
        &:hover {
            background-color: var(--tc-primary-500);
            border-color: var(--tc-primary-500);
        }
    }
    .text-footer {
        border-bottom: 0px;
        margin-bottom: 0px;
    }
}
.box-faq-16 {
    .accordion-item {
        border: 0px;
        box-shadow: none;
        border-bottom: 1px solid #012b4323;
        margin-bottom: 0px;
    }
    .accordion-button {
        padding-left: 0px;
        padding-right: 0px;
    }
    .accordion-body {
        padding-left: 0px;
        padding-right: 0px;
    }
    .accordion-button:focus {
        box-shadow: none;
    }
}