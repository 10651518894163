.style-green {
    .image-faq-1 {
        background-color: var(--tc-primary-1500);   
    }
    &.box-faqs-12 .accordion-button::after, .box-faqs-12 .accordion-button:not(.collapsed)::after {
        background-color: var(--tc-primary-1500);
    }
}
.box-image-about-us-2 {
    position: relative;
    margin-bottom: 100px;
    padding-right: 75px;
    .image-about-1 {
        position: relative;
        padding: 16px 0px 0px 16px;
        &::before {
            content: "";
            width: 207px;
            height: 90%;
            border-radius: 20px;
            background-color: var(--tc-primary-500);
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;
        }
        img {
            position: relative;
            z-index: 2;
        }
        &::after {
            content: "";
            height: 193px;
            width: 208px;
            background: url(../imgs/pages/about/dot.png) no-repeat 0px 0px;
            position: absolute;
            z-index: 1;
            top: -65px;
            left: 252px;
        }
    }
    .image-about-2 {
        position: absolute;
        bottom: -88px;
        right: 0px;
        z-index: 3;
        img {
            border: 5px solid var(--tc-theme-border-7);
            border-radius: 25px;
        }
    }
}
.box-info-about-line {
    position: relative;
    padding-left: 180px;
    &::before {
        content: "";
        height: 2px;
        background-color: var(--tc-primary-1400);
        position: absolute;
        width: 130px;
        top: 35px;
        left: 0px;
        z-index: 1;
    }
}
.box-info-about-top {
    padding-left: 55px;
}
.line-primary-1400 {
    &::before {
        background-color: var(--tc-primary-1400);
    }
}
.box-about-us-2 {
    padding: 120px 0px;
}
.style-green-about-3 {
    background-color: var(--tc-theme-main);
    .color-white {
        color: var(--tc-dark-950) !important;
    }
}
.box-progress-gradient-2 {
    .progress-bar {
        background-image: linear-gradient(to right, #1AB69D, #31B978);
    }
}
.mb-70 {
    margin-bottom: 60px;
}
.style-no-bg {
    &::before {
        display: none;
    }
}
.footer-blue {
    .slide-footer-brand-inner-2 {
        background-color: var(--tc-primary-1400) !important;
    }
    .form-newsletter .btn-newsletter {
        background-color: var(--tc-primary-1400) !important;
    }
    svg {
        fill: var(--tc-primary-1400);
    }
    .list-socials a:hover svg {
        fill: var(--tc-primary-1400);
    }
}
.box-number-black {
    background-color: var(--tc-neutral-1200);
    padding: 0px;
}