.footer-style-10 {
    background-color: var(--tc-dark-950);
    background-image: url(../imgs/pages/404/bg-footer-2.png);
    background-position: center;
    .top-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 1px solid var(--tc-primary-1400);
        .top-footer-left {
            width: 50%;
        }
        .top-footer-right {
            width: 50%;
            max-width: 474px;
        }
    }
    .form-newsletter .form-control {
        border-radius: 20px;
        background-color: transparent;
        border: 1px solid var(--tc-theme-main);
        color: var(--tc-theme-main);
        &::placeholder {
            color: var(--tc-theme-main);
        }
    }
    .form-newsletter .btn-newsletter {
        border-radius: 0px 20px 20px 0px;
        background-color: var(--tc-primary-home);
    }
    .list-socials {
        a {
            background-image: none;
            border: 1px solid var(--tc-theme-border-9);
            border-radius: 5px;
            height: 35px;
            width: 35px;
            line-height: 32px;
            margin-right: 15px;
            svg {
                fill: var(--tc-theme-main);
            }
            &:hover {
                svg {
                    fill: var(--tc-primary-home);
                }
            }
        }
    }
    .footer-bottom {
        border-top: 0px;
        padding-bottom: 60px;
    }
}
.text-slide {
    font-size: 67px;
    line-height: 115%;
    font-family: var(--tc-ag-font-family);
    color: transparent;
    -webkit-text-stroke: 1px var(--tc-theme-main);
    font-weight: bold;
}
.slide-footer-brand {
    width: 100%;
    overflow: hidden;
    padding-top: 53px;
    padding-bottom: 38px;
    .slide-footer-brand-inner {
        overflow: hidden;
        transform: rotate(3deg);
        background-color: var(--tc-dark-950);
        height: 280px;
        margin-bottom: -168px;
        margin-left: -30px;
        .slide-footer-brand-inner-2 {
            background-color: var(--tc-primary-home);
            margin: 0px -30px;
            padding: 10px 0px;
            .container {
                max-width: 100%;
                padding: 0px 130px;
            }
        }
    }
}